import APIService from './APIService';

export default {
  getAllVersions() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indger-versions/get-all-versions`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getAllActivatedVersions() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indger-versions/get-all-activated-versions`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getAllVersionsByCompany(companyId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indger-versions/get-all-versions-by-company?company_id=${companyId}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getLastVersionByCompany(companyId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indger-versions/get-last-version-by-company?company_id=${companyId}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getLastVersionByCompanyAndDataMonth(companyId, dataMonth) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indger-versions/get-last-version-by-company-and-data-month?company_id=${companyId}&data_month=${dataMonth}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  setSelectedActivedVersion(id, data) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post(`/indger-versions/set-selected-activated-version/${id}`, JSON.stringify(data))
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
}